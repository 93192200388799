import { IdmClientContext, ucetAppConfig } from "@sista/idm-client";
import {
  ErrorFallback,
  ErrorScreen,
  logi,
  RedirectHome,
  UiContext,
} from "@sista/library-stasi";
import React, { useContext, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";

import { ConfigContextProvider } from "../../hooks/ConfigContext";
import { useLocalNavigation } from "../../hooks/useLocalNavigation";
import { featureOrganizationAdmin } from "../../utils/features";
import { IdmUiContext } from "../../utils/IdmUiContext";
import { OrganizationRegisterDialog } from "../dialogs/organization/OrganizationRegisterDialog";
import { ChooseProfileAfterLogin } from "../dialogs/registration/ChooseProfileAfterLogin";
import { AdHocTaskScreen } from "../screens/AdHocTaskScreen";
import { DashboardScreen } from "../screens/DashboardScreen";
import { DemoScreen } from "../screens/DemoScreen";
import { DepartmentAdminScreen } from "../screens/department/DepartmentAdminScreen";
import { OrganizationAdminScreen } from "../screens/organization/OrganizationAdminScreen";
import { OrganizationDelegateScreen } from "../screens/organization/OrganizationDelegateScreen";
import { PersonSettingsScreen } from "../screens/PersonSettingsScreen";
import { ProfilesSettingsScreen } from "../screens/ProfilesSettingsScreen";
import { TasksOverviewScreen } from "../screens/TasksOverviewScreen";

export const FullAccountContent: React.FC = () => {
  const { loggedAccount, roles, profileChosenByUser } =
    useContext(IdmClientContext);
  const { t } = useTranslation("ucet");
  const { registrationIntent } = useContext(IdmUiContext);
  const { openDialog, openedDialog, openedDialogData } = useContext(UiContext);

  const localNavigationConfig = useLocalNavigation();

  const clientId = ucetAppConfig.oAuthClientId;

  const isoAuth = ucetAppConfig.oAuth === "true";

  const isIstaLogin = isoAuth && clientId === "ista" && loggedAccount != null;

  const toChooseFrom = isIstaLogin
    ? loggedAccount.profiles.filter((p) => p.type == "ISTA")
    : loggedAccount.profiles.filter((p) => p.type != "ISTA");

  const needsToChooseProfile =
    isoAuth &&
    loggedAccount != null &&
    toChooseFrom.length > 1 &&
    !profileChosenByUser;

  const showMainContent =
    roles != null && loggedAccount != null && !needsToChooseProfile;

  const niaError = t(
    "ucet.error.divergentNia",
    "Údaje, které nám o Vás poskytla Identita občana, se liší od údajů, pod kterými jste přihlášen(a). Zkontrolujte prosím jméno, příjmení a datum narození. Buď bude potřeba přihlásit se správným SISTA účtem, nebo bude nutné sjednotit Vaše data s Identitou občana (Nastavení, Osobní údaje), případně prosím kontaktujte Help desk (viz Pomoc)."
  );

  useEffect(() => {
    if (registrationIntent === "EXPERT") {
      window.location.href = new URL(
        "/experts/ui/expert",
        window.location.origin
      ).toString();
    } else if (registrationIntent === "APPLICANT") {
      window.location.href = new URL(
        "/ucet/",
        window.location.origin
      ).toString();
    }
  }, [registrationIntent]);

  logi(
    "FullAccountContent showMainContent=" +
      showMainContent +
      ", needsToChooseProfile=" +
      needsToChooseProfile +
      ", toChooseFrom=" +
      toChooseFrom.length +
      ", registrationIntent=" +
      registrationIntent +
      ", openedDialog=" +
      openedDialog +
      ", openedDialogData=" +
      openedDialogData +
      ", clientId=" +
      clientId +
      ", isoAuth=" +
      isoAuth +
      ", isIstaLogin=" +
      isIstaLogin
  );

  return (
    <ConfigContextProvider>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {openedDialog == "registerOrganization" && (
          <OrganizationRegisterDialog />
        )}

        {showMainContent && (
          <Routes>
            <Route path="/" element={<DashboardScreen />} />
            <Route path="/_demo/" element={<DemoScreen />} />
            <Route path="/ukoly/" element={<TasksOverviewScreen />} />
            <Route path="/ukol/:context" element={<AdHocTaskScreen />} />
            <Route path="/register" element={<RedirectHome />} />
            <Route path="/settings/person" element={<PersonSettingsScreen />} />
            <Route
              path="/settings/profiles"
              element={<ProfilesSettingsScreen />}
            />
            <Route
              path={"/sprava-resortu/:departmentId"}
              element={<DepartmentAdminScreen />}
            />
            {featureOrganizationAdmin && (
              <>
                <Route
                  path={"/sprava-organizace/:organizationId"}
                  element={<OrganizationAdminScreen />}
                />
                <Route
                  path={"/sprava-organizace/:organizationId/:view"}
                  element={<OrganizationAdminScreen />}
                />
                <Route
                  path={"/sprava-organizace/:organizationId/verify/:requestId"}
                  element={<OrganizationDelegateScreen />}
                />
              </>
            )}
            <Route
              path="/divergent-nia-fields"
              element={<ErrorScreen error={niaError} />}
            />
            {/* http://localhost:8200/onboarding?invitedEmail=aaa@tomucha.cz */}
            <Route
              path={"/onboarding"}
              element={
                <ErrorScreen
                  error={t(
                    "ucet.error.loggedOnboarding",
                    "Snažíte se využít odkaz z pozvánky SISTA, ale aktuálně jstě již nějakým účtem přihlášen(a). Odhlašte se a pak teprve klikněte na odkaz v pozvánce."
                  )}
                />
              }
            />
            <Route path={"*"} element={<RedirectHome />} />
          </Routes>
        )}
        {needsToChooseProfile && (
          <ChooseProfileAfterLogin
            profiles={toChooseFrom}
          ></ChooseProfileAfterLogin>
        )}
      </ErrorBoundary>
    </ConfigContextProvider>
  );
};
