import { Stack } from "@mui/material";
import { AlertsContext, DistaSelect } from "@sista/dista-ui";
import {
  countryCodes,
  countryLabels,
  normalizePhoneNumber,
  Profile,
  Right,
  SistaAutocompleteElement,
  SistaDialog,
  SistaFormHeader,
  SmartButton,
  UiContext,
  UpdatePersonProfileDTO,
  useEmailValidation,
  useMaxLengthValidation,
  useMinLengthValidation,
  useNameValidation,
  usePhoneNumberValidation,
  useRequiredValidation,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { useCallback, useContext } from "react";
import { useForm } from "react-hook-form";
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import { useRejstrikClient } from "../../../api/utils/useRejstrikClient";
import { useGender } from "../../../hooks/useGender";

export type OrganizationEditForm = UpdatePersonProfileDTO;
export type OrganizationEditProfileDialogProps = {
  organizationId: string;
  profile: Profile;
  additionalData: UpdatePersonProfileDTO;
  onDone: () => unknown;
};

export const OrganizationEditProfileDialog = (
  props: OrganizationEditProfileDialogProps
) => {
  const { closeDialogs } = useContext(UiContext);
  const alerts = useContext(AlertsContext);

  //const organizationGenerichKey = props.organizationId.replace(":", ".");
  //const organizationGenerichCode = organizationGenerichKey.split(".")[1];

  /* const storedSection = (props.profile as any)._viewData?.d[
    organizationGenerichCode
  ]?.details?.section;
  const storedInfo = (props.profile as any)._viewData?.d[
    organizationGenerichCode
  ]?.details?.info; */

  const form = useForm<OrganizationEditForm, unknown>({
    defaultValues: {
      ...props.additionalData,
    },
  });
  const { t } = useTranslation("ucet");

  const phone = usePhoneNumberValidation();
  const email = useEmailValidation();
  //const maxLength = useMaxLengthValidation(37);
  const maxInfoLength = useMaxLengthValidation(1000);
  //const minLength = useMinLengthValidation(2);
  const required = useRequiredValidation();
  //  const reasonableDate = useReasonableDateValidation();
  const nameValidation = useNameValidation();

  const { genders, genderName } = useGender();

  const { updateProfileInOrganization, updatePersonProfileInOrganization } =
    useRejstrikClient();

  const updateProfileImpl = useCallback(
    async (formData: OrganizationEditForm) => {
      formData.phone = normalizePhoneNumber(formData.phone);
      await updatePersonProfileInOrganization({
        organizationId: props.organizationId,
        profileId: props.profile.id,
        ...formData,
      });
      alerts.showSuccess(
        t("ucet.organization.profileUpdated", "Profil byl upraven")
      );
      props.onDone();
      closeDialogs();
    },
    [alerts, closeDialogs, props, t, updatePersonProfileInOrganization]
  );

  const [updateProfileCall, updateProfileState] =
    useSafeSistaAsync(updateProfileImpl);

  const g = form.watch("gender");

  return (
    <SistaDialog
      title={t("ucet.organization.editDialogTitle", "Změna vybraných údajů")}
      onClose={closeDialogs}
    >
      <SistaFormHeader
        title={t("ucet.organization.editTitle", "Změna profilu")}
        subtitle={t(
          "ucet.organization.editSubtitle",
          "O případné další změny požádejte administrátora SISTA."
        )}
      />

      <FormContainer onSuccess={updateProfileCall} formContext={form}>
        <Stack direction="column" spacing={2}>
          <TextFieldElement
            name={"titlesBefore"}
            label={t(
              "ucet.organization.form.titlesBefore",
              "Tituly před jménem"
            )}
          />
          <TextFieldElement
            name={"titlesAfter"}
            label={t("ucet.organization.form.titlesAfter", "Tituly za jménem")}
          />
          <DistaSelect
            autoWidth
            fullWidth
            label={t("ucet.organization.form.gender", "Pohlaví")}
            selectedMenuItem={g}
            menuItems={genders.map((g) => ({
              id: g,
              text: genderName(g),
            }))}
            onChange={(e) => form.setValue("gender", e.target.value as any)}
            //variant="outlined"
          />
          <SistaAutocompleteElement
            optionValues={countryCodes}
            optionLabels={countryLabels}
            name={"nationality"}
            validation={{ ...required }}
            label={t(
              "ucet.organization.form.nationality",
              "Státní příslušnost"
            )}
            multiple={false}
          />
          <TextFieldElement
            name="email"
            autoComplete={"off"}
            label={t("ucet.organization.form.email", "Kontaktní email")}
            validation={{
              ...email,
            }}
          />
          <TextFieldElement
            name="phone"
            placeholder={t(
              "ucet.organization.form.phonePlaceholder",
              "999 999 999"
            )}
            autoComplete={"off"}
            label={t("ucet.organization.form.phone", "Kontaktní telefon")}
            validation={{
              ...phone,
            }}
          />
          <Right sx={{ marginTop: 4 }}>
            <SmartButton state={updateProfileState}>
              {t("ucet.organization.buttonEdit", "Uložit změny")}
            </SmartButton>
          </Right>
        </Stack>
      </FormContainer>
    </SistaDialog>
  );
};
